/*************************
    Custom Styling
**************************/
.request-set-password-title {
    margin-top: 1.5rem;
    text-align: center;
}

.request-set-password-description {
    font-size: medium;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.request-set-password-note {
    font-size: small;
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
}

.request-set-password-checkbox {
    font-size: medium;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.request-set-password-button-group {
    display: table;
    margin-left: auto;
    margin-right: 0;
    margin-top: 1.5rem;
}

.request-set-password-button-primary {
    width: 140px;
}

.request-set-password-button-secondary {
    width: 140px;
}

.request-set-password-input-group {
    display: flex;
    align-items: center;
}
